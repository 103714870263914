<template>
  <div>
    <top-vertical-nav></top-vertical-nav>
    <div class="leason-container" id="#main">
      <div class="member_nav_logo" style="position: relative">
        <a target="_blank">
          <img src="../../assets/image/member_login_logo.png" alt />
        </a>
      </div>
      <div class="course-container-top bgfff">
        <div class="course-card-title">推薦課程</div>
        <div class="container-types clearfix">
          <div
            @click="checkDetails(i)"
            v-for="(course, index) in topCourseList"
            :key="index"
            class="course-card-container"
          >
            <div class="course-card-top">
              <img
                class="course-card-picture"
                :src="'data:image/png;base64,' + course.course_img"
              />
              <div class="course-label">
                <label>{{ course.course_type }}</label>
              </div>
            </div>
            <div class="course-card-content">
              <div class="course-card-name">
                {{ course.course_name }}
              </div>
              <p class="course-card-desc">{{ course.course_introduction }}</p>
              <div class="course-card-price">
                <span>价格：{{ course.course_price }}點</span>
                <div class="collect">
                  <el-button size="small" type="success">購買課程</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="course-container">
        <div class="course-card-title">課程分類</div>
        <div class="course-category-container">
          <div class="course-category-type">
            <div class="category_title">年級:</div>
            <div
              :class="{
                'course-category-btn btn_grade': true,
                active_type: gradeType == 'all',
              }"
              @click="changeType('all', '', 'grade')"
              key="gall"
            >
              全部
            </div>
            <div
              v-for="(item, index) in hsdGradeList"
              :class="{
                'course-category-btn btn_grade': true,
                active_type: gradeType == index,
              }"
              @click="changeType(index, item, 'grade')"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="course-category-type">
            <div class="category_title culum_color">科目:</div>
            <div
              class="course-category-btn btn_culum"
              :class="{ active_btn: culumType == 'all' }"
              @click="changeType('all', '', 'curriculum')"
              key="call"
            >
              全部
            </div>
            <div
              class="course-category-btn btn_culum"
              v-for="item in courseCulumList"
              :class="{ active_btn: culumType == item.id }"
              @click="changeType(item.id, item.curriculum, 'curriculum')"
              :key="item.id"
            >
              {{ item.curriculum }}
            </div>
            <div class="category_title version_color">版本:</div>

            <div
              class="course-category-btn btn_version"
              :class="{ active_btn: versionType == 'all' }"
              @click="changeType('all', '', 'course_version')"
              key="vall"
            >
              全部
            </div>
            <div
              class="course-category-btn btn_version"
              v-for="(item, index) in courseVersionData"
              :class="{ active_btn: versionType == item.id }"
              @click="
                changeType(item.id, item.course_version, 'course_version3')
              "
              :key="'version' + index"
            >
              {{ item.course_version }}
            </div>
            <el-button type="primary" @click="getFilterCourseData"
              >查找</el-button
            >
          </div>

          <div class="course-category-type"></div>
        </div>
        <div class="container-types clearfix">
          <div
            @click="checkDetails(i)"
            v-for="(course, index) in courseFliterList"
            :key="index"
            class="course-card-container"
          >
            <div class="course-card-top">
              <img
                class="course-card-picture"
                :src="'data:image/png;base64,' + course.course_img"
              />
              <div class="course-label">
                <label>{{ course.course_type }}</label>
              </div>
            </div>
            <div class="course-card-content">
              <div class="course-card-name">
                {{ course.course_name }}
              </div>
              <p class="course-card-desc">{{ course.course_introduction }}</p>
              <div class="course-card-price">
                <span>价格：{{ course.course_price }}點</span>
                <div class="collect">
                  <el-button
                    size="small"
                    @click="createClassOrder"
                    type="success"
                    >購買課程</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import topVerticalNav from "../childComponent/topVerticalNav.vue";
import {
  getTopCourseList,
  getFilterCourseList,
  getCourseTypeList,
  getHsdCulum,
  getCourseVersion,
  checkCourseOrder,
  createCourseOrder,
} from "../../api/api";
export default {
  data() {
    return {
      topCourseList: [],
      courseTypeList: [],
      courseVersionData: [],
      courseCulumList: [],
      courseFliterList: [],
      currentLeason: ["数学", "英语", "国语"],
      courseSearchData: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
        grade: "",
        curriculum: "",
        course_version: "",
      },
      gradeType: "all",
      culumType: "all",
      versionType: "all",
      list: 0,
    };
  },
  components: { topVerticalNav },
  computed: {
    ...mapState(["hsdGradeList"]),
  },
  created() {
    this.getTopCourseData();
    this.getFilterCourseData(); //根据条件获取类型课程
    let grade = this.hsdGradeList[0];
    // this.getCulumData(grade);
  },
  methods: {
    async getCulumData(grade) {
      const res = await getHsdCulum({grade});
      if (res.success == "1") {
        this.courseCulumList = res.data;
        this.courseSearchData.curriculum = "";
        // res.data &&
        //   res.data.length &&
        //   this.getCourseVersionData({
        //     grade,
        //     curriculum: res.data[0].curriculum,
        //   });
      }
    },
    async getTopCourseData() {
      const res = await getTopCourseList();
      if (res.success == "1") {
        this.topCourseList = res.data;
      }
    },
    async getCourseTypeData() {
      const res = await getCourseTypeList();
      if (res.success == "1") {
        this.courseTypeList = res.data;
      }
    },
    async getCourseVersionData(list) {
      const res = await getCourseVersion(list);
      if (res.success == "1") {
        this.courseTypeList = res.data && res.data.length ? res.data : [];
        this.courseSearchData.course_version = "";
      }
    },
    //按条件筛选课程
    async getFilterCourseData() {
      const res = await getFilterCourseList(this.courseSearchData);
      if (res.success == "1") {
        console.log(res);
        this.courseSearchData.total = res.data.count;
        this.courseFliterList =
          res.data.data && res.data.data.length ? res.data.data : [];
      }
    },
    //课程筛选条件变化
    checkTypeChanged() {
      const { curriculum, course_version, ...rest } = this.courseSearchData;
      if (this.gradeType == "all") {
        this.culumType = "all";
        this.courseCulumList = [];
        this.courseVersionData = [];
        this.courseSearchData = { ...rest, curriculum: "", course_version: "" };
      }
      if (this.culumType == "all") {
        this.versionType = i;
        this.courseVersionData = [];
        this.courseSearchData = { ...rest, curriculum, course_version: "" };
      }
    },
    //初始化课程订单
    async createClassOrder() {
      
    },
    changeType(i, v, id) {
      if (i == "all") {
        this.courseSearchData[id] = "";
        if (id == "grade") {
          this.gradeType = i;
        } else if (id == "curriculum") {
          this.culumType = i;
        } else {
          this.versionType = i;
        }
        this.checkTypeChanged();
      } else {
        this.courseSearchData[id] = v;
        if (id == "grade") {
          let grade = this.hsdGradeList[i];
          this.gradeType = i;
          this.getCulumData(grade);
        } else if (id == "curriculum") {
          let grade = this.hsdGradeList[this.gradeType],
            curriculum = v;
          this.culumType = i;
          this.getCourseVersionData({ grade, curriculum });
        } else {
          this.versionType = i;
        }
      }
    },
    checkDetails(leasonId) {
      console.log(leasonId);
      this.$router.push("/leasonDetail");
    },
  },
};
</script>
<style>
@import "../../assets/css/leason.css";
</style>